import React, { useContext } from "react";
import { motion } from "framer-motion";
import Card from "./Card";
import { tarotDeck } from "../utils/constants";
import './Topic.css';
import { GameStateContext } from "../contexts/GameState.context";

const Topic = ({ topic, cards, handleCardDrop }) => {
  const { pastRef, presentRef, futureRef, hoveredTopic } = useContext(GameStateContext);
  const topicRef = topic === "Past" ? pastRef : topic === "Present" ? presentRef : futureRef;

  return (
    <motion.div
      ref={topicRef}
      className={"topic"}
      transition={{ duration: 0.3 }}
    >
      <div className={`topic-cards ${hoveredTopic === topic ? 'topic-hovered' : ''}`}>
        {cards.map((card, index) => (
          <Card
          key={card.id}
          cardBack="/assets/tarot/back.png"
          cardFront={tarotDeck.find(c => c.id === card.id).front}
          cardId={card.id}
          marginRight={0}
          isOnDeck={false}
          handleCardDrop={handleCardDrop}
          index={index}
          currentTopic={topic}
          isFlipped={!!card.isFront}
          // index={index} //TODO: use this to calculate random rotation
          />
        ))}
      </div>
      <div className="topic-title">{topic}</div>
    </motion.div>
  );
};

export default Topic;
