// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/image/blur-light.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game {
  display: flex;
  height: 100%;
  font-family: Arial, sans-serif;
}

.main-content {
  flex: 1 1;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  /* padding: 20px; */

  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
  background-size: contain;
}

.deck-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-top: 20px;
}

.card {
  width: 120px;
  height: 200px;
}

/* .card-inner {
  width: 100%;
  height: 100%;
  transform-origin: center;
} */

.topics {
  margin: 40px 0 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Game.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;;EAEnB,2EAAuE;EACvE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;;;;GAIG;;AAEH;EACE,mBAAmB;AACrB","sourcesContent":[".game {\n  display: flex;\n  height: 100%;\n  font-family: Arial, sans-serif;\n}\n\n.main-content {\n  flex: 1;\n  text-align: center;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  /* padding: 20px; */\n\n  background: url(../assets/image/blur-light.png) center center no-repeat;\n  background-size: contain;\n}\n\n.deck-container {\n  display: flex;\n  flex: 1 1 auto;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.card {\n  width: 120px;\n  height: 200px;\n}\n\n/* .card-inner {\n  width: 100%;\n  height: 100%;\n  transform-origin: center;\n} */\n\n.topics {\n  margin: 40px 0 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
