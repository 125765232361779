export const tarotDeck = [
  { id: 0, front: "/assets/tarot/major_0_the_fool.png", meaning: "The Fool: New beginnings." },
  { id: 1, front: "/assets/tarot/major_1_the_magician.png", meaning: "The Magician: Manifestation." },
  { id: 2, front: "/assets/tarot/major_2_the_high_priestess.png", meaning: "The High Priestess: Intuition." },
  { id: 3, front: "/assets/tarot/major_3_the_empress.png", meaning: "The Empress: Something." },
  { id: 4, front: "/assets/tarot/major_4_the_emperor.png", meaning: "The Emperor: Something." },
  { id: 5, front: "/assets/tarot/major_5_the_hierophant.png", meaning: "The Hierophant: Something." },
  { id: 6, front: "/assets/tarot/major_6_the_lovers.png", meaning: "The Lovers: Something." },
  { id: 7, front: "/assets/tarot/major_7_the_chariot.png", meaning: "The Chariot: Something." },
  { id: 8, front: "/assets/tarot/major_8_the_strength.png", meaning: "Strength: Something." },
  // Add more cards...
];

export const topics = ["Past", "Present", "Future"];

export const colors = {
  primary: "#5527b9",
  secondary: "#b37df6"
}