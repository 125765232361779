import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Deck from "./Deck";
import Topic from "./Topic";
import { tarotDeck, topics } from "../utils/constants";
import "./Game.css";
import SideBanner from "./SideBanner";
import ShuffleCardAnimation from "./ShuffleCardAnimation";

const Game = () => {
  const [shuffledDeck, setShuffledDeck] = useState([]);
  const [topicCards, setTopicCards] = useState({
    Past: [],
    Present: [],
    Future: [],
  });
  const [isShuffling, setIsShuffling] = useState(false);
  const [isDeckOut, setIsDeckOut] = useState(false);

  const shuffleDeck = () => {
    if (isShuffling) {
      setIsShuffling(false);
      setIsDeckOut(false);
      return;
    }
    setIsShuffling(true);

    // Check if any topic has cards
    const hasCards = Object.values(topicCards).some(
      (cards) => cards.length > 0
    );

    // If topics have cards, shuffle current deck, otherwise shuffle full deck
    const shuffled = hasCards
      ? [...shuffledDeck].sort(() => Math.random() - 0.5)
      : [...tarotDeck].sort(() => Math.random() - 0.5);
    setShuffledDeck(shuffled);
  };

  useEffect(() => {
    const shuffled = [...tarotDeck].sort(() => Math.random() - 0.5);
    setShuffledDeck(shuffled);
  }, []);

  const handleDrop = (card, position, currentTopic) => {
    // Remove card from shuffledDeck
    if (!currentTopic) {
      setShuffledDeck((prevShuffledDeck) =>
        prevShuffledDeck.filter((c) => c.id !== card.id)
      );
    } else { 
      if (currentTopic === position) {
        return;
      }
      setTopicCards((prevTopicCards) => {
        const updatedTopicCards = {
          ...prevTopicCards,
          [currentTopic]: [...prevTopicCards[currentTopic].filter((c) => c.id !== card.id)],
        };
        return updatedTopicCards;
      });
    }
    // Add card to the corresponding topic
    setTopicCards((prevTopicCards) => {
      const updatedTopicCards = {
        ...prevTopicCards,
        [position]: [...prevTopicCards[position], card],
      };
      return updatedTopicCards;
    });
  };

  return (
    <div className="game">
      <SideBanner />
      <div className="main-content">
        <div className="topics">
          {topics.map((topic) => (
            <Topic
              key={topic}
              topic={topic}
              cards={topicCards[topic]}
              handleCardDrop={handleDrop}
            />
          ))}
        </div>
        <button
          onClick={shuffleDeck}
          style={{
            backgroundColor: "#6e2ad4",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            margin: "20px 0",
            fontSize: "16px",
            width: "200px",
            alignSelf: "center",
          }}
        >
          {isShuffling ? "Stop Shuffle" : "Shuffle Deck"}
        </button>
        <div className="deck-container">
          <AnimatePresence mode="wait" 
            onExitComplete={() => console.log("exit complete")}
          >
            {isShuffling ? 
              <ShuffleCardAnimation key="shuffle-card-animation" deckCount={18} isShuffling={isShuffling}/> : 
              <Deck
                key="deck"
                cards={shuffledDeck}
                handleCardDrop={handleDrop}
              />
            }
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Game;
