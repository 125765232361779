// Indiviual Tarot card game
import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { GameStateContext } from "../contexts/GameState.context";
import { containsPoint } from "../utils/cursor";
import useSound from "use-sound";
import HOVER_SOUND from "../assets/audio/hover.wav";
import DRAG_END_SOUND from "../assets/audio/drag_end.wav";

const topicNumberMap = {
  Past: 1,
  Present: 2,
  Future: 3
}

const Card = ({ index, cardBack, cardFront, marginRight, animationDelay, cardId, handleCardDrop, isOnDeck, currentTopic, isFlipped }) => {
  const [isFront, setIsFront] = useState(isFlipped);
  const [isDragging, setIsDragging] = useState(false); // This is to prevent card flipping when dragging
  // const [isOnDeck, setIsOnDeck] = useState(true); //TODO: this is now set from parent, but should be fixed later
  // Generate a random rotation angle between -10 and 10 degrees once
  const [randomRotation] = useState(() => Math.random() * 10 - 5);

  // Access the refs from the context
  const { pastRef, presentRef, futureRef, hoveredTopic, setHoveredTopic } = useContext(GameStateContext);

  const [playHoverSound] = useSound(HOVER_SOUND, {
    volume: 0.05
  });
  const [playDragEndSound] = useSound(DRAG_END_SOUND, {
    volume: 0.4
  });

  const cardVariants = {
    initial: {  
      y: 300,
    },
    initialOnTopic: {  
      y: 10,
      scale: 1.1,
    },
    animate: {
      marginRight: marginRight,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      }
    },
    animateOnTopic: {
      rotate: randomRotation,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      }
    },
    exit: {
      marginRight: 0,
      y: 300,
      transition: {
        duration: 1,
        ease: "easeInOut",
      }
    }
  }

  useEffect(() => {
    if (!hoveredTopic) {
      return;
    }
    playHoverSound({ playbackRate: 1 + 0.1 * topicNumberMap[hoveredTopic] }); //TODO: this is hardcoded, should be dynamic. And the sound too big compared to the others
  }, [hoveredTopic]);

  const handleFlip = (e) => {
    if (isOnDeck) {
      return;
    }

    setIsFront(true); // It should be flipped once only
  };

  function handleDragStart() {
    console.log('Dragging started');
    setIsDragging(true);
  }

  function handleDrag(event, info) {
    const { point } = info;

    if (pastRef.current && presentRef.current && futureRef.current) {
      if (containsPoint(pastRef.current, point.x, point.y)) {
        setHoveredTopic('Past');
      } else if (containsPoint(presentRef.current, point.x, point.y)) {
        setHoveredTopic('Present');
      } else if (containsPoint(futureRef.current, point.x, point.y)) {
        setHoveredTopic('Future');
      }
    }
  }

  function handleDragEnd(event, info) {
    setIsDragging(false);
    console.log('Dragging ended', event, info);

    if (!!hoveredTopic) {
      handleCardDrop({ id: cardId, front: cardFront, isFront }, hoveredTopic, !isOnDeck && currentTopic);
      setHoveredTopic(null);
      // setIsOnDeck(false); //TODO: this is now set from parent, but should be fixed later
    }

    playDragEndSound();
  }

  const preventDefaultDrag = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <motion.div
        className="card"
        onTap={!isDragging && handleFlip}
        variants={cardVariants}
        initial={isOnDeck ? "initial" : "initialOnTopic"}
        animate={isOnDeck ? "animate" : "animateOnTopic"}
        exit={isOnDeck && "exit"}
        style={{
          perspective: 1000,
          cursor: "pointer",
          // marginRight: marginRight,
          rotate: isOnDeck ? 0 : randomRotation,
          zIndex: index + 1,
        }}
        whileDrag={{
          scale: 1.25,
          rotate: 5,
          boxShadow: "10px 10px 1px rgba(0, 0, 0, 0.2)",
          cursor: "grabbing",
          zIndex: 1000,
          // transition: "0s",
        }}
        drag={true}
        dragElastic={1}
        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
        onDragStart={() => {
          handleDragStart();
        }}
        onDrag={(event, info) => {
          handleDrag(event, info);
        }}
        onDragEnd={(event, info) => handleDragEnd(event, info)}
        whileHover={isOnDeck && {
          rotate: -5,
          x: -5,
          y: -35,
          transition: {
            duration: 0.22,
            ease: "easeInOut",
          }
        }}
      >
        <motion.div
          className="card-inner"
          animate={!isFlipped && { rotateY: isFront ? 180 : 0 }}
          transition={{ duration: 0.6 }}
          style={{
            transformStyle: "preserve-3d",
            position: "relative",
          }}
        >
          {!isFlipped && <motion.img
            src={cardBack}
            alt="Tarot card back"
            draggable="false"
            onDragStart={preventDefaultDrag}
            style={{
              position: "absolute",
              backfaceVisibility: "hidden",
              width: "100%",
              left: "0",
            }}
          />}
          <motion.img
            src={cardFront}
            alt="Tarot card front"
            draggable="false"
            onDragStart={preventDefaultDrag}
            style={{
              position: "absolute",
              backfaceVisibility: "hidden",
              width: "100%",
              left: "0",
              transform: !isFlipped && "rotateY(180deg)",
            }}
          />
        </motion.div>
      </motion.div>
    </>
  );
};

export default Card;