import React from 'react';
import './SideBanner.css';

const SideBanner = () => {
  return (
    <div className="side-banner">
      <button>+</button>
    </div>
  );
};

export default SideBanner;
