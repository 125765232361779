import React, { useState } from "react";
import Card from "./Card";
import { motion } from "framer-motion";
import "./Deck.css";

const Deck = ({ cards, handleCardDrop }) => {

  return (
    <motion.div className="deck" key={"deck"}>
      {cards.map((card, index) => {
        const marginRight = (cards.length/2 - index) * 50; // Calculate margin-right based on index
        const animationDelay = index * 0.2; // Calculate animation delay based on index

        return (
          <Card
            key={card.id}
            index={index}
            cardBack="/assets/tarot/back.png"
            cardFront={card.front}
            marginRight={marginRight}
            animationDelay={animationDelay}
            cardId={card.id}
            handleCardDrop={handleCardDrop}
            isOnDeck={true}
            isFlipped={false}
          />
        );
      })}
    </motion.div>
  );
};

export default Deck;

