// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topic-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333; /* Darker text color */
  margin-top: 10px;
  text-align: center;
}

.topic {
  
  display: inline-block;
  justify-content: center;
  margin: 0 10px;
  position: relative;

}


.topic-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  width: 120px;
  height: 200px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ededed;
  transition: 'box-shadow 0.3s ease-in-out'
}

.topic-hovered {
  border: 1px solid #7f4ee7;
  background-color: #d8cfe3;
}`, "",{"version":3,"sources":["webpack://./src/components/Topic.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW,EAAE,sBAAsB;EACnC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,qBAAqB;EACrB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;;AAEpB;;;AAGA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;;EAEvB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB;AACF;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".topic-title {\n  font-size: 1rem;\n  font-weight: bold;\n  color: #333; /* Darker text color */\n  margin-top: 10px;\n  text-align: center;\n}\n\n.topic {\n  \n  display: inline-block;\n  justify-content: center;\n  margin: 0 10px;\n  position: relative;\n\n}\n\n\n.topic-cards {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  \n  width: 120px;\n  height: 200px;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  background-color: #ededed;\n  transition: 'box-shadow 0.3s ease-in-out',\n}\n\n.topic-hovered {\n  border: 1px solid #7f4ee7;\n  background-color: #d8cfe3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
