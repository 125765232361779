// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1; /* This is to ensure the header is above the game */
}

.header-logo {
  height: 32px;
  margin-right: 20px;
  object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,wCAAwC;EACxC,UAAU,EAAE,mDAAmD;AACjE;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  background-color: white;\n  overflow: hidden;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  z-index: 1; /* This is to ensure the header is above the game */\n}\n\n.header-logo {\n  height: 32px;\n  margin-right: 20px;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
