import React, { createContext, useRef, useState } from 'react';

// Create a context
export const GameStateContext = createContext();

// Create a provider component
export const GameStateProvider = ({ children }) => {
  // Create refs for each topic
  const pastRef = useRef(null);
  const presentRef = useRef(null);
  const futureRef = useRef(null);

  // State to track which topic is being hovered
  const [hoveredTopic, setHoveredTopic] = useState(null);

  // Context value to be provided
  const contextValue = {
    pastRef,
    presentRef,
    futureRef,
    hoveredTopic,
    setHoveredTopic, // Provide a function to update the hovered topic
  };

  return (
    <GameStateContext.Provider value={contextValue}>
      {children}
    </GameStateContext.Provider>
  );
};
