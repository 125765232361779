// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shuffle-card-animation {
    display: grid;
    grid-template-areas: "cards";
}
  
.shuffle-card {
    grid-area: cards;
    display: flex;
    width: 100px;
    /* border-radius: 0.5rem; */

    /* aspect-ratio: 2.5/3.5; */
    /* background-size: contain; */
}`, "",{"version":3,"sources":["webpack://./src/components/ShuffleCardAnimation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,2BAA2B;;IAE3B,2BAA2B;IAC3B,8BAA8B;AAClC","sourcesContent":[".shuffle-card-animation {\n    display: grid;\n    grid-template-areas: \"cards\";\n}\n  \n.shuffle-card {\n    grid-area: cards;\n    display: flex;\n    width: 100px;\n    /* border-radius: 0.5rem; */\n\n    /* aspect-ratio: 2.5/3.5; */\n    /* background-size: contain; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
