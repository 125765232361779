// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.side-banner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  gap: 0.5rem;
  width: 20%; /* 1/5 of the screen */
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  min-width: 300px;
  /* background-color: #f0f0f0; */
  padding: 20px;
  /* background-color: #f8f8f8; */
  border-right: 0.5px solid #e0e0e0;
  /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
}

.side-banner button {
  width: 6rem;
  height: 10rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ededed;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/SideBanner.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,qCAAqC;EACrC,2BAA2B;EAC3B,WAAW;EACX,UAAU,EAAE,sBAAsB;EAClC,qBAAqB,EAAE,8BAA8B;EACrD,mBAAmB,EAAE,4BAA4B;EACjD,gBAAgB;EAChB,+BAA+B;EAC/B,aAAa;EACb,+BAA+B;EAC/B,iCAAiC;EACjC,8CAA8C;AAChD;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["\n.side-banner {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-auto-rows: min-content;\n  gap: 0.5rem;\n  width: 20%; /* 1/5 of the screen */\n  justify-items: center; /* Center items horizontally */\n  align-items: center; /* Center items vertically */\n  min-width: 300px;\n  /* background-color: #f0f0f0; */\n  padding: 20px;\n  /* background-color: #f8f8f8; */\n  border-right: 0.5px solid #e0e0e0;\n  /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */\n}\n\n.side-banner button {\n  width: 6rem;\n  height: 10rem;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  background-color: #ededed;\n  color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
