import React from "react";
import { GameStateProvider } from "./contexts/GameState.context";
import Layout from "./components/Layout";

function App() {
  return (
    <div className="App">
      <GameStateProvider>
        <Layout />
      </GameStateProvider>
    </div>
  );
}

export default App;