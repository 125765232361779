import React, { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion";
import "./ShuffleCardAnimation.css";
import backImage from "../assets/tarot/back.png";

const ShuffleCardAnimation = ({ isShuffling, deckCount }) => {
  const container = useRef();
  useGSAP(
    () => {
      let tl = gsap.timeline({ 
        repeat: -1, 
        yoyoEase: true 
      });

      tl.add(driftIn())
        .add(shuffleCards(), "<")
        .add(scaleCards(), "<")
        .add(driftOut(), "<55%");
    },
    { scope: container }
  );

  let cardsMidIndex = Math.floor(deckCount / 2);
  let yOffset = 200;
  let scaleOffset = 0.02;
  let duration = 0.8;
  let scaleDuration = duration / 3;

  function driftIn() {
    return gsap.timeline().from(".shuffle-card-animation", {
      x: -yOffset / 3,
      duration,
      ease: "power2.inOut",
      yoyoEase: true
    });
  }

  function driftOut() {
    return gsap.timeline().to(".shuffle-card-animation", {
      x: yOffset / 3,
      duration,
      ease: "power2.inOut",
      yoyoEase: true
    });
  }

  function scaleCards() {
    return gsap
      .timeline()
      .to(".shuffle-card", {
        scale: (i) => {
          if (i <= cardsMidIndex) {
            return 1 - i * scaleOffset;
          } else {
            return 1 - (deckCount - 1 - i) * scaleOffset;
          }
        },
        delay: duration / 3,
        duration: scaleDuration,
        ease: "expo.inOut",
        yoyoEase: true
      })
      .to(".shuffle-card", { scale: 1, duration: scaleDuration });
  }

  function shuffleCards() {
    return gsap
      .timeline()
      .set(".shuffle-card", {
        y: (i) => -i * 0.5
      })
      .fromTo(
        ".shuffle-card",
        {
          rotate: -10,
          x: -yOffset
        },
        {
          duration,
          rotate: 25,
          x: yOffset,
          stagger: duration * 0.03,
          ease: "expo.inOut",
          yoyoEase: true
        }
      );
  }

  // function shuffleDeck() {
  //   tl.add(driftIn())
  //     .add(shuffleCards(), "<")
  //     .add(scaleCards(), "<")
  //     .add(driftOut(), "<55%");
  // }

  return <motion.div className="shuffle-card-animation"
    key={"shuffle-card-animation"}
    ref={container}
    initial={{ y: 500 }}
    animate={{ y: 0 , transition: { duration: 1 }}}
    exit={{ y: 500 , transition: { duration: 1 }}}
  >
    {[...Array(deckCount)].map((_, index) => (
      <img key={index} className="shuffle-card" src={backImage} />
    ))}
  </motion.div>;
};

export default ShuffleCardAnimation;
